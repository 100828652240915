import React from 'react';
import { dashboard } from '../../urls';

import './style.scss';

function Ad() {
	return (<a href={dashboard + '/link'} target="_blank" className={`ad`}>
		<img src={dashboard + '/ad'} />
	</a>);
}

export default Ad;
