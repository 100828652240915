import React from 'react';

import {mainSite} from '../../urls';

function AmazonAds() {
    return (
        <iframe src={`${mainSite}/amzn/`}></iframe>
    );
}

export default AmazonAds;