import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

import WeatherDetailsList from '../../components/WeatherDetails/List';
import WeatherForm from '../../components/WeatherForm/weather';
import CurrentWeather from '../../components/Weather/current';

import { setPreferencesInit } from '../../actions/PreferencesActions';
import { dashboard, mainSite } from '../../urls';

import './style.scss';

function Intro({ units, setPreferencesInit, categories, preferences, daily_forecast, hourly_forecast, coordinates, ...props }) {
	let location = useLocation();
	const history = useHistory();

	var handleExitClick = function (e) {
		setPreferencesInit(true);
	};

	// Load Search Results
	var handleSearchSubmit = function (query) {
		setPreferencesInit(true);
		history.push('/search?q=' + query);
	};

	return (
		<div>
			<div
				style={
					props.showBg
						? props.bg
							? { backgroundImage: `url('${props.bg}')` }
							: {
									backgroundImage: `url('${dashboard}/background')`,
							  }
						: null
				}
				className={`toolbar intro-page${props.showBg ? ' bg' : ''}${
					props.darkMode ? ' dark' : ''
				}`}
			>
				<section className="hero">
					<div className="container">
						<h1>
							{location.pathname == '/intro'
								? 'Search Weather'
								: 'Weather Search Installed!'}
						</h1>
						<WeatherForm />
					</div>
				</section>

				<section className="intro">
					<div className={`container`}>
						<Link to="/" onClick={handleExitClick} className="exit">
							&times;
						</Link>
						<div className="mt-5 mb-5">
							<CurrentWeather />
						</div>
						{hourly_forecast && (
							<div className="owl-theme-category mb-5">
								<h4 className="text-center">Hourly Forecast</h4>
								<WeatherDetailsList type="hourly" items={hourly_forecast} format="h A" />
							</div>
						)}
						{daily_forecast && (
							<div className="owl-theme-category mb-5">
								<h4 className="text-center">Daily Forecast</h4>
								<WeatherDetailsList type="daily" items={daily_forecast} format="MMM Do" />
							</div>
						)}
						{ coordinates && (
						<div className="text-center">
							<a href={`${mainSite}/weather/?lat=${coordinates.lat}&lon=${coordinates.lng}&units=${units}`} className="btn btn-primary btn-lg">View Detailed Weather Information</a>
						</div>
						)}
					</div>
				</section>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
		preferences: state.preferences.init,
		daily_forecast: state.weather.daily_forecast,
		hourly_forecast: state.weather.hourly_forecast,
		coordinates: state.weather.coordinates,
		units: state.weather.units
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
})(Intro);
