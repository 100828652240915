import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPreferencesInit } from '../../actions/PreferencesActions';
import Moment from 'react-moment';


function Detail({ item, format, units }) {
	const [date] = useState(new Date( item.dt * 1000 ));

	return (
		<Link
			to={`/listings`}
			className="category-item icon"
		>
			<img
				alt={item.weather[0].main}
				src={`https://openweathermap.org/img/w/${item.weather[0].icon}.png`}
			/>
			<span>
				<Moment format={format}>{date}</Moment>
				<br/>
				{Math.round(item.temp.day ? item.temp.day : item.temp)}&deg; {units === 'metric' ? 'C' : 'F'}
			</span>
		</Link>
	);
}

const mapStateToProps = (state) => {
	return {
		units: state.weather.units
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
})(Detail);
