import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';

import toolBarApp from './reducers';

const persistConfig = {
	key: 'root',
	storage: storage,
	blacklist: ['app']
};

const logger = createLogger({
	collapsed: true
});

const middleware = process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];

const middlewares = compose(applyMiddleware(...middleware));
const persistedReducer = persistReducer(persistConfig, toolBarApp);

export const store = createStore(persistedReducer, middlewares);
export const persistor = persistStore(store);
