import React from 'react';

import Checkbox from '../CheckboxItem';

import './style.scss';

function SettingItem({ name, onCheckBoxChange, isChecked }) {
	return (
		<div className="setting-item clearfix">
			<label>
				{name}
				<Checkbox onChange={onCheckBoxChange} checked={isChecked} />
			</label>
		</div>
	);
}

export default SettingItem;
