import React, { useEffect, useState } from 'react';

import { HashRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import WeatherDetails from './components/WeatherDetails';

import Header from './components/Header';
import Footer from './components/Footer';
import Keep from './components/Keep';

import Home from './pages/Home/';
import Intro from './pages/Intro/';
import ListingsPage from './pages/Listings/';
import SearchPage from './pages/Listings/Search';

import { createAppToken, getFavorites } from './actions/AppActions';
import { setPreferencesInit, increaseInitCounter } from './actions/PreferencesActions';

import './App.scss';
import { connect } from 'react-redux';

function App({
	preferences,
	setPreferencesInit,
	increaseInitCounter,
	token,
	createAppToken,
	getFavorites,
}) {
	const [display, setDisplay] = useState(localStorage.getItem('display_keep_it1') || '');

	useEffect(() => {
		if (display !== '' && !preferences) {
			setPreferencesInit(true);
		}
	}, [display]);

	useEffect(() => {
		increaseInitCounter();
	}, [token]);

	return (
		<Router>
			<Header />
			<WeatherDetails selfDisplay={display} />
			<Switch>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>
	);
}

var mapStateToProps = (state) => {
	return {
		token: state.app.token,
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {
	createAppToken,
	getFavorites,
	setPreferencesInit,
	increaseInitCounter,
})(App);
