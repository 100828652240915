import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'react-icons-kit';
import { getQuote } from '../../actions/ToolbarActions';

import { androidCheckmarkCircle } from 'react-icons-kit/ionicons/androidCheckmarkCircle';
import { gear } from 'react-icons-kit/fa/gear';
import Todos from '../Todos';
import {toggleModal} from '../../urls';

import Settings from '../Settings';

import './style.scss';

function Footer({ showQuote, showTodoList, getQuote, quote }) {
	useEffect(() => {
		if (showQuote) getQuote();
	}, [showQuote, getQuote]);

	return (
		<footer>
			<Todos />

			{showTodoList && (
				<div className="todo">
					<button onClick={() => toggleModal('todos-open')}>
						<Icon size="25" icon={androidCheckmarkCircle} />
						Todo
					</button>
				</div>
			)}
			{showQuote && <p>{quote}</p>}
			<div className="config">
				<button onClick={() => toggleModal('settings-open')}>
					<Icon size="35" icon={gear} />
				</button>
			</div>

			<Settings />
		</footer>
	);
}

const mapStateToProps = (state) => {
	return {
		showTodoList: state.settings.options.todos.visible,
		quote: state.toolbar.quote,
		showQuote: state.settings.options.quote.visible,
	};
};

export default connect(mapStateToProps, { getQuote })(Footer);
