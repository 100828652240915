export const mainSite = 'https://onlineweatherapp.com';
export const dashboard = 'https://dash.onlineweatherapp.com';
// export const dashboard = 'https://weatherdash.loc';

export const toggleModal = (modal) => {
    if (document.body.classList[1] === modal) {
        document.body.className = '';
    } else {
        document.body.className = '';
        document.body.classList.add('modal-open');
        document.body.classList.add(modal);
    }
};