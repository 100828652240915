import React, { useState } from 'react';
import { connect } from 'react-redux';

import AutoComplete from '../tripod-components/autocomplete';
import { geocode } from '../tripod-components/util/bing';

import { setUnits, setUserLocation } from '../../actions/WeatherActions';

import { Icon } from 'react-icons-kit';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';

import './style.scss';

function WeatherForm({
	showWeather,
	setUserLocation
}) {
	// State
	// const [userLocation, setUserLocation] = useState(null);
	const [bingKey] = useState(
		'AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16'
	);

	const handleLocationSelect = function(data) {
		geocode(data, bingKey).then((data) => {
			// setUserLocation(data.city, data.state, { lat: data.point[0], lng: data.point[1] })
			window.location = 'https://onlineweatherapp.com/weather/?lat='+data.point[0]+'&lon='+data.point[1]+'&units=imperial';
		});
	};

	if (!showWeather) 
		return null;

	return (
		<form className="weather-search">
			<fieldset className="search">
				<div className="auto-complete">
					<AutoComplete
						index="3"
						// location={userLocation}
						apiKey={bingKey}
						id="js-destination-geoip"
						placeholder="Search City"
						onLocationSet={(data) => {}}
						onLocationSelect={handleLocationSelect}
						suggestionsClass="intro-autocomplete"
					/>
					<button>
						<Icon size="30" icon={iosSearchStrong} />
					</button>
				</div>
			</fieldset>
		</form>
	);
}

const mapStateToProps = (state) => {
	return {
		units: state.weather.units,
		showWeather: state.settings.options.weather.visible,
		city: state.weather.city,
	};
};

export default connect(mapStateToProps, { 
	setUnits, 
	setUserLocation
})(WeatherForm);
