import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import './style.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Detail from './Detail.js';

import { getCategories } from '../../actions/AppActions';

function WeatherDetailsList({ type, format, items, categories, getCategories, ...props }) {
	const [carouselKey, setCarouselKey] = useState(0);
	useEffect(() => {
		setCarouselKey(carouselKey+1);
	}, [items]);

	return (
		<>
			{items.length ? (
				<div className="owl-theme-category">
					<OwlCarousel
						key={carouselKey}
						options={{
							nav: true,
							dots: false,
							rewind: false,
							slideBy: 4,
							navText: ['<span>‹</span>', '<span>›</span>'],
							responsive: {
								0: { items: 4 },
								768: { items: 6 },
								1200: { items: 10 },
							},
						}}
					>
						{items.map((item, index) => <Detail format={format} item={item} key={type + index + carouselKey} />)}
					</OwlCarousel>
				</div>
			) : (
				<div className="loading"></div>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.app.categories,
	};
};

export default connect(mapStateToProps, {
	getCategories,
})(WeatherDetailsList);
