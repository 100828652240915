import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import WeatherDetailsList from './List';

import { Icon } from 'react-icons-kit';
import { chevronCircleUp } from 'react-icons-kit/fa/chevronCircleUp';
import { chevronCircleDown } from 'react-icons-kit/fa/chevronCircleDown';

import { setCollapsed } from '../../actions/PreferencesActions';

import {mainSite} from '../../urls';

function WeatherDetails({ units, daily_forecast, hourly_forecast, coordinates, weatherDetails, collapsed, setCollapsed, showBg, bg, darkMode, preferences }) {
	const location = useLocation();
	const [weatherView, setWeatherView] = useState('hourly');

	return (
		<section
			style={
				showBg
					? bg
						? { backgroundImage: `url('${bg}')` }
						: { backgroundImage: `url('../../images/bg.jpg)` }
					: null
			}
			className={`categories pt-3 pb-2${showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}${
				collapsed ? ' collapsed' : ''
			}`}
		>
			<span
				className="collapse-icon"
				onClick={() => {
					setCollapsed();
				}}
			>
				{collapsed ? (
					<Icon size={40} icon={chevronCircleDown} />
				) : (
					<Icon size={40} icon={chevronCircleUp} />
				)}
			</span>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<ul className="list-inline text-center weather-switch">
							{hourly_forecast.length > 0 && (<li className="list-inline-item"><a href="#" onClick={() => setWeatherView('hourly')} className={weatherView == 'hourly' ? "btn btn-sm btn-primary" : "btn btn-sm"}>Hourly Forecast</a></li>)}
							{daily_forecast.length > 0 && (<li className="list-inline-item"><a href="#" onClick={() => setWeatherView('5-day')} className={weatherView == '5-day' ? "btn btn-sm btn-primary" : "btn btn-sm"}>5-day Forecast</a></li>)}
							{weatherDetails && (<li className="list-inline-item"><a className="btn btn-sm" href={`${mainSite}/weather/?lat=${coordinates.lat}&lon=${coordinates.lng}&units=${units}`}>Show Details</a></li>)}
						</ul>
						{(weatherView == '5-day') ? (
							<WeatherDetailsList format="MMM Do" type="daily" items={daily_forecast} />
						) : (
							<WeatherDetailsList format="h A"  type="hourly" items={hourly_forecast} />
						)}
					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		showBg: state.settings.options.background.visible,
		preferences: state.preferences.init,
		collapsed: state.preferences.collapsed,
		bg: state.settings.backgroundURL,
		darkMode: state.settings.darkMode,
		daily_forecast: state.weather.daily_forecast,
		hourly_forecast: state.weather.hourly_forecast,
		coordinates: state.weather.coordinates,
		weatherDetails: state.weather.api,
		units: state.weather.units
	};
};

export default connect(mapStateToProps, {
	setCollapsed,
})(WeatherDetails);
