import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getWeather } from '../../actions/WeatherActions';
import { toggleModal, mainSite } from '../../urls';

import './style.scss';

function CurrentWeather({ country, getWeather, weatherData, units, city, state }) {
	
	useEffect(() => {
		if(!weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weatherData]);

	useEffect(() => {
		if(weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units]);

	if (weatherData === null) 
		return null;

	const getWindDirection = function(deg) {
		if(deg == 0) 
			return 'N';
		if(deg < 90)
			return 'NE';
		if(deg == 90)
			return 'E';
		if(deg < 180)
			return 'SE';
		if(deg == 180)
			return 'S';
		if(deg < 270)
			return 'SW';
		if(deg == 270)
			return 'W';
		if(deg < 360)
			return 'NW';
	};

	if(!weatherData)
		return;

	return (
		<div className="weather intro-weather">
			<h1>{city}, {state ? state : country}</h1>
			<div class="row  align-items-center">
				<div class="col-4 text-center p-0">
					<img 
						class="w-icon" 
						width="75" 
						src={`https://openweathermap.org/img/w/${weatherData.weather[0].icon}.png`} 
						alt={weatherData.weather[0].main}
					/>
					<span class="temp">{Math.round(weatherData.temp)} &deg;{units === 'metric' ? 'C' : 'F'}</span>
				</div>
				<div class="col-8 p-0">
					<div class="meta">
						{weatherData.feels_like && (<p className="mb-2">Feels like {Math.round(weatherData.feels_like)} &deg;{units === 'metric' ? 'C' : 'F'} &nbsp;{weatherData.weather[0].main} ({weatherData.weather[0].description})</p>)}
						<ul>
							<li><img src={`${mainSite}/icon-1.png`} width="20"/> {Math.round(weatherData.wind_speed)}m/s {getWindDirection(weatherData.wind_deg)}</li>
							<li><img src={`${mainSite}/icon-2.png`} width="20"/> {weatherData.pressure}hPa</li>
							<li>Humidity: {weatherData.humidity}%</li>
							<li>Visibility: {Math.round(weatherData.visibility / 1000)}km</li>
							<li>Max temp: {Math.round(weatherData.temp_max)} &deg;{units === 'metric' ? 'C' : 'F'}</li>
							<li>Min temp: {Math.round(weatherData.temp_min)} &deg;{units === 'metric' ? 'C' : 'F'}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		weatherData: state.weather.api,
		units: state.weather.units,
		city: state.weather.city,
		state: state.weather.state,
		country: state.weather.country,
	};
};

export default connect(mapStateToProps, { getWeather  })(CurrentWeather);
