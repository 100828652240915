import {
	SET_WEATHER_API,
	SET_UNITS,
	SET_CITY,
	SET_COORDINATES,
	SET_STATE,
	SET_HOURLY_FORECAST,
	SET_5_DAY_FORECAST
} from '../actions/types';

const INITIAL_STATE = {
	api: null,
	units: 'imperial',
	city: '',
	state: '',
	coordinates: null,
	daily_forecast: [],
	hourly_forecast: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_WEATHER_API:
			var weatherData = action.payload.current;
			weatherData.temp_max = action.payload.daily[0].temp.max;
			weatherData.temp_min = action.payload.daily[0].temp.min;
			return { ...state, api: weatherData };
		case SET_UNITS:
			return { ...state, units: action.payload };
		case SET_CITY:
			return { ...state, city: action.payload };
		case SET_STATE:
			return { ...state, state: action.payload };
		case SET_COORDINATES:
			return { ...state, coordinates: action.payload };
		case SET_HOURLY_FORECAST:
			var hourly_forecast = [];
			for(var i = 0; i<8; i++) {
				hourly_forecast.push(action.payload.hourly[i]);
			}
			return {...state, hourly_forecast: hourly_forecast };
		case SET_5_DAY_FORECAST:
			var daily_forecast = [];
			for(var i = 1; i<=5; i++) {
				daily_forecast.push(action.payload.daily[i]);
			}
			return {...state, daily_forecast: daily_forecast };
		default:
			return state;
	}
};
