import { 
	SET_WEATHER_API, 
	SET_COORDINATES, 
	SET_UNITS, 
	SET_CITY, 
	SET_STATE,
	SET_HOURLY_FORECAST,
	SET_5_DAY_FORECAST
} from './types';

import { geocode } from '../components/tripod-components/util/bing';

import {dashboard} from '../urls';

const bingKey = 'AshH7R2MJYwFD5t64YhV5DAT-ttMsPhNoIPQYZfpB38kUDgMqXWcMXyeMnTj3O16';

const getWeatherApiURL = (city, units, lat, lng) => {
	return `${dashboard}/weatherDetails?lat=${lat}&lon=${lng}&units=${units}`;
};

function getUserLocation(dispatch, preferences) {
	if(!preferences) {
		const first_search = localStorage.getItem('first_search');
		if(first_search) {
			geocode(first_search, bingKey).then((data) => {
				dispatch(setUserLocation(data.city, data.state, { lat: data.point[0], lng: data.point[1] }));
			}).catch(() => {
				getUserLocation(dispatch, true);
			});
			return;
		}
	}
	if(window.geoip2) {
		window.geoip2.city(function(res){
			if(!res || !res.location){
				dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }))
			} else {
				dispatch(setUserLocation(
					res.city.names.en,
					res.subdivisions ? res.subdivisions[0].names.en : res.country.names.en,
					{ 
						lat: res.location.latitude , 
						lng: res.location.longitude 
					}
				));
			}
		}, function(err){
			dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }));
		});
	} else {
		// No geoip set Default Location
		dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }));
	}
}

function getWeatherInformation(city, units, coordinates, callback) {
	fetch(getWeatherApiURL(city, units, coordinates.lat, coordinates.lng),{
		method: "GET"
	}).then(response => response.json())
	  .then(response => {
		callback(response);
	  });
}

export const getWeather = () => {
	return (dispatch, getState) => {
		var { units, city, state, coordinates } = getState().weather;
		if(!coordinates || !state || !city){
			var preferences = getState().preferences.init;
			getUserLocation(dispatch, preferences);
		} else {
			getWeatherInformation(city, units, coordinates, function(response) {
				dispatch({ type: SET_WEATHER_API, payload: response });
				dispatch({ type: SET_HOURLY_FORECAST, payload: response });
				dispatch({ type: SET_5_DAY_FORECAST, payload: response });
			});
		}
	};
}; 

export const setUserLocation = (city, state, location) => {
	return dispatch => {
		dispatch(setCity(city));
		dispatch(setState(state));
		dispatch(setCoordinates(location));
		dispatch(getWeather());
	};
}

export const setCity = value => {
	return { type: SET_CITY, payload: value };
};

export const setState = value => {
	return { type: SET_STATE, payload: value };
};

export const setCoordinates = data => {
	return { type: SET_COORDINATES, payload: data };
};

export const setUnits = value => {
	return { type: SET_UNITS, payload: value };
};
