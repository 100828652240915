import React from 'react';
import WeatherOptions from '../Settings/Weather';

function WeatherModal() {
    return (
        <div className="weather-modal">
            <WeatherOptions />
        </div>
    );
}

export default WeatherModal;