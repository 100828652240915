import {
	SET_OPTION_SELECTED,
	SET_SINGLE_OPTION_SELECTED,
	REMOVE_OPTION_SELECTED,
	SET_PREFERENCES_INIT,
	SET_COLLAPSED,
	INCREASE_INIT_COUNTER,
} from '../actions/types';

const INITIAL_STATE = {
	preferences: {
		categories: [],
		cuisines: [],
		allergies: [],
		diets: [],
		ingredients: [],
		skills: [],
		goals: [],
	},
	init: false,
	stepSelected: 0,
	collapsed: false,
	initCounter: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_COLLAPSED:
			return {
				...state,
				collapsed: !state.collapsed,
			};
		case SET_PREFERENCES_INIT:
			return {
				...state,
				init: action.payload,
			};
		case INCREASE_INIT_COUNTER:
			return {
				...state,
				// collapsed: state.initCounter == 4 ? true : state.collapsed,
				initCounter: state.initCounter + 1,
			};
		case SET_OPTION_SELECTED:
			return {
				...state,
				preferences: {
					...state.preferences,
					[action.payload.parent]: [
						...state.preferences[action.payload.parent],
						action.payload.option,
					],
				},
			};
		case SET_SINGLE_OPTION_SELECTED:
			return {
				...state,
				preferences: {
					...state.preferences,
					[action.payload.parent]: [action.payload.option],
				},
			};
		case REMOVE_OPTION_SELECTED:
			let newPreferences = state.preferences[action.payload.parent];
			newPreferences = newPreferences.filter(function (value) {
				return value != action.payload.option;
			});
			return {
				...state,
				preferences: {
					...state.preferences,
					[action.payload.parent]: newPreferences,
				},
			};
		default:
			return state;
	}
};
