import React from 'react';
import { connect } from 'react-redux';
import { setBackground } from '../../actions/SettingsActions';

import Options from './Options';
import Upload from './Upload';

import {mainSite} from '../../urls';

import './style.scss';

function Settings({ bg, setBackground, visible }) {
	return (
		<div className="settings">
			<div className="row">
				<div className="col-sm-6">
					<Upload bg={bg} setBackground={setBackground} />
				</div>
				<div className="col-sm-6">
					<Options />
				</div>
			</div>
			<ul className="legal-links">
				<li>
					<a target="_blank" href={`${mainSite}/Privacy.html`}>Privacy Policy</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Terms.html`}>EULA</a>
				</li>

				<li>
					<a target="_blank" href={`${mainSite}/Endorsement.html`}>DMCA Policy</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Uninstall.html`}>Uninstall</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Contact.html`}>Contact Us</a>
				</li>
			</ul>
		</div>
	);
}
const mapStateToProps = state => {
	return {
		bg: state.settings.backgroundURL
	};
};

export default connect(mapStateToProps, { setBackground })(Settings);
