import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Form from '../../components/Form';
import Ad from '../../components/Ad';
import ShortCutsList from '../../components/ShortCutsList';
import { selectCategory } from '../../actions/AppActions';
import { getWeather } from '../../actions/WeatherActions';
import { dashboard } from '../../urls';
import WeatherForm from '../../components/WeatherForm/weather';

import moment from 'moment';

import './style.scss';

function Home({ weatherData, getWeather, units, selectCategory, darkMode, ...props }) {
	useEffect(() => {
		selectCategory(null);
	}, []);
	
	useEffect(() => {
		if(!weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weatherData]);

	useEffect(() => {
		if(weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units]);

	let salute = 'Evening';
	const now = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
	const hour = parseInt(now.format('H'));
	if (hour >= 17 && hour < 21) {
		salute = 'Evening';
	} else if (hour >= 4 && hour < 12) {
		salute = 'Morning';
	} else if (hour >= 12 && hour < 17) {
		salute = 'Afternoon';
	}

	return (
		<div
			style={
				props.showBg
					? props.bg
						? { backgroundImage: `url('${props.bg}')` }
						: { backgroundImage: `url('${dashboard}/background')` }
					: null
			}
			className={`toolbar ${props.showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}`}
		>
			<section className="main-content intro-page">
				<h1>Search Weather</h1>
				<WeatherForm />
			</section>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
		weatherData: state.weather.api,
		units: state.weather.units
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	getWeather
})(Home);
